@import "../assets/scss/_variables.scss";
@import "../assets/scss/_typography.scss";

section {
  margin: 1.5rem 0;

  .sample-component {
    padding: 1rem;
    border-bottom: 1px solid darkgray;
  }
}

h3 {
  text-decoration: underline;
}

.button-container {
  display: flex;
  flex-wrap: wrap;
  > * {
    padding: 1rem;
    max-width: 33%;
    flex-basis: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.sample-card {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .title {
    color: $success-600;
    @include body-bold;
  }

  .company {
    background-color: $grey-100;
    padding: 24px;
    .name {
      color: $grey-800;
      @include body-small-bold;
    }
    .address {
      @include body-small;
    }
  }

  .total-price {
    color: $grey-900;
    .total-label {
      text-align: left;
    }
    @include heading-title-28-bold;
    .price {
      text-align: right;
      align-self: flex-end;
    }
    @media screen and (max-width: $tablet-device-max-width) {
      text-align: right;
      .total-label {
        text-align: right;
      }
      @include heading-title-20-bold;
      .col {
        @include heading-title-28-bold;
      }
    }
  }

  // mock step 1
  .title-group {
    display: flex;
    align-items: center;
    gap: 8px;
    .step {
      line-height: 32px;
      text-align: center;
      height: 32px;
      width: 32px;
      background-color: $success-600;
      border-radius: 50%;
      color: $white;
      @include heading-title-24;
    }
  }

  form {
    color: $grey-800;
    background-color: $grey-100;
    padding: 16px;
    @media screen and (max-width: $tablet-device-max-width) {
      padding: 8px;
    }
  }

  // mock step 2

  // mock success card
  .payment-detail {
    padding: 24px;
    background-color: $grey-100;
    .detail-group {
      padding-bottom: 20px;
      &:last-child {
        padding-bottom: 0;
      }
    }
    .label {
      @include body-small;
    }
    .detail {
      @include body-bold;
      &.amount {
        @include heading-title-24-bold;
      }
    }
  }
}
@media print {
  .main-page {
    padding-top: 7.5rem; /* Or any other value that provides enough space for the navbar */
  }
}
