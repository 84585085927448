button,
a {
  font-family: OpenSans;
  outline: none;
  box-shadow: none;
}

button:focus,
button:focus-visible {
  outline: none !important;
  box-shadow: none !important;
}

.dc-button {
  height: 48px;
  min-width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: 1px solid $grey-400;
  border-radius: 4px;
  padding: 0 12px;
  box-shadow: none;
  background-color: $grey-100;
  color: $grey-800;
  white-space: nowrap;
  @include body-bold;
  svg path {
    fill: $grey-800;
  }
  &:hover {
    background-color: $grey-200;
    border-color: $grey-500;
  }
  &:active {
    border-color: $grey-200;
    background-color: $grey-200;
  }
  &:disabled {
    pointer-events: none;
    border-color: $grey-100;
    color: $grey-500;
    background-color: $grey-100;
    box-shadow: none;
    svg path {
      fill: $grey-500;
    }
  }

  &.small {
    height: 32px;
    min-width: 32px;
    line-height: 16px;
    @include body-small-bold;
  }

  &.extra {
    height: 85px;
    width: 100%;
    @include heading-title-24-bold;
  }

  &.primary {
    box-shadow: 0px 0px 2px rgba(15, 23, 42, 0.14), 0px 2px 2px rgba(15, 23, 42, 0.12), 0px 1px 3px rgba(15, 23, 42, 0.2);
    background-color: var(--btn-primary-color);
    border-color: var(--btn-primary-color);
    color: $white;
    svg path {
      fill: $white;
    }
    &:hover {
      background-color: var(--btn-primary-hover-color);
      border-color: var(--btn-primary-hover-color);
    }
    &:active {
      border-color: var(--btn-primary-active-color);
      background-color: var(--btn-primary-active-color);
      box-shadow: none;
    }
    &:focus {
      background-color: var(--btn-primary-color);
      border-color: var(--btn-primary-color);
      box-shadow: 0px 0px 2px rgba(15, 23, 42, 0.14), 0px 2px 2px rgba(15, 23, 42, 0.12), 0px 1px 3px rgba(15, 23, 42, 0.2) !important;
    }
  }

  &.secondary {
    box-shadow: 0px 0px 2px rgba(15, 23, 42, 0.14), 0px 2px 2px rgba(15, 23, 42, 0.12), 0px 1px 3px rgba(15, 23, 42, 0.2);
    background-color: $white;
    color: var(--btn-secondary-font-color);
    border-color: $grey-200;
    svg path {
      fill: var(--btn-secondary-font-color);
    }
    &:hover {
      background-color: var(--btn-secondary-hover-color);
      border-color: var(--btn-secondary-hover-color);
    }
    &:active {
      background-color: var(--btn-secondary-active-color);
      border-color: var(--btn-secondary-hover-color);
      box-shadow: none;
    }
    &:focus {
      background-color: $white;
      color: var(--btn-secondary-font-color);
      border-color: $grey-200;
      box-shadow: 0px 0px 2px rgba(15, 23, 42, 0.14), 0px 2px 2px rgba(15, 23, 42, 0.12), 0px 1px 3px rgba(15, 23, 42, 0.2) !important;
    }
  }

  &.outlined {
    border: 1px solid;
    border-color: $grey-400;
    background-color: $white;
    color: $grey-800;
    box-shadow: none;
    svg path {
      fill: $grey-800;
    }
    &:hover {
      border-color: var(--btn-primary-hover-color);
      color: var(--btn-primary-hover-color);
      svg path {
        fill: var(--btn-primary-hover-color);
      }
    }
    &:active {
      border-color: var(--btn-primary-active-color);
      color: var(--btn-primary-active-color);
      svg path {
        fill: var(--btn-primary-active-color);
      }
    }
    &:focus {
      border-color: $grey-400;
      background-color: $white;
      color: $grey-800;
      svg path {
        fill: $grey-800;
      }
    }
    &:disabled {
      pointer-events: none;
      border-color: $grey-500;
      color: $grey-500;
      box-shadow: none;
      svg path {
        fill: $grey-500;
      }
    }
  }

  &.outlined-dark {
    border: 1px solid;
    border-color: $white;
    background-color: transparent;
    color: $white;
    box-shadow: none;
    svg path {
      fill: $white;
    }
    &:hover {
      border-color: $white;
      color: $white;
      background-color: rgba(255, 255, 255, 0.2);
      svg path {
        fill: $white;
      }
    }
    &:active {
      border-color: $white;
      color: $white;
      background-color: rgba(255, 255, 255, 0.5);
      svg path {
        fill: $white;
      }
    }
    &:disabled {
      border: 1px solid;
      pointer-events: none;
      border-color: rgba(255, 255, 255, 0.5);
      color: $grey-500;
      box-shadow: none;
      svg path {
        fill: $grey-500;
      }
    }
  }

  &.link {
    padding: 0;
    height: auto;
    background-color: transparent;
    color: $info-500;
    border: none;
    box-shadow: none;
  }

  &:focus,
  &:active:focus {
    @extend :active;
  }

  &:hover,
  &:active,
  &:focus,
  &:visited,
  &:focus-visible,
  &:focus-within,
  &:target,
  &:active:hover {
    outline: none !important;
  }
}

button:disabled {
  svg path {
    fill: $grey-500;
  }
}
