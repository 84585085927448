/*
* The tutorial from
* https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_custom_checkbox
*/

$checkmark-bk-color: var(--primary-color);
$checkmark-bk-overdue-color: $danger-500;
$checkmark-bk-disabled-color: $grey-500;

/* The dc-checkbox */
.dc-checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 25px;
  line-height: 28px;
  padding-top: 4px;
  cursor: pointer;
  @include body-regular;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* Large size of checkbox */
  &.large {
    line-height: 32px;
    height: 32px;
    padding-left: 44px;
    padding-top: 0;
  }

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    margin-top: 6px;
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 4px;
    border: 1px solid $grey-400;
    background-color: white;

    /* Large size of checkbox */
    &.large {
      margin-top: 0;
      height: 32px;
      width: 32px;

      /* Style the checkmark/indicator Large*/
      &:after {
        left: 9px;
        top: 3px;
        width: 10px;
        height: 18px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    /* Style the checkmark/indicator default*/
    &:after {
      left: 6px;
      top: 1px;
      width: 6px;
      height: 12px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

/* On mouse-over, add a grey background color */
.dc-checkbox:hover input ~ .checkmark {
  background-color: white;
}

/* When the checkbox is checked, add a blue background */
.dc-checkbox input:checked ~ .checkmark {
  transition-duration: 0.7s;
  transition: opacity 1;
  background-color: $checkmark-bk-color;
  &.overdue {
    background-color: $checkmark-bk-overdue-color;
  }
  &.disabled {
    background-color: $checkmark-bk-disabled-color;
  }
}

/* Create the checkmark/indicator (hidden when not checked) */
.dc-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.dc-checkbox input:checked ~ .checkmark:after {
  display: block;
}
