@import "../../assets/scss/base";

a {
  height: 110px;
}

a img {
  max-height: 110px;
  height: 100%;
  width: 100%;
}

.dc-footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  position: sticky;
  @include body-small;

  .footer-logo {
    max-height: 110px;
    max-width: 130px;
    margin-left: 0px;
    margin-right: -10px;
    margin-top: -1px;
    border-style: none;
    vertical-align: middle;
  }
}
