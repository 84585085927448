@import "../assets/scss/_variables.scss";

.loading-container {
  .loading-message {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: $heading-font-size-20;
    font-weight: normal;
    margin-top: 100px;
  }
}
