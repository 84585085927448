.heading-title-40-bold {
  font-size: $heading-font-size-40;
  font-weight: bold;
}
.heading-title-40 {
  font-size: $heading-font-size-40;
  font-weight: normal;
}
.heading-title-36-bold {
  font-size: $heading-font-size-36;
  font-weight: bold;
}
.heading-title-36 {
  font-size: $heading-font-size-36;
  font-weight: normal;
}
.heading-title-32-bold {
  font-size: $heading-font-size-32;
  font-weight: bold;
  line-height: 1.28;
}
.heading-title-32 {
  font-size: $heading-font-size-32;
  font-weight: normal;
}
.heading-title-28-bold {
  font-size: $heading-font-size-28;
  font-weight: bold;
  line-height: 1.32;
}
.heading-title-28 {
  font-size: $heading-font-size-28;
  font-weight: normal;
}
.heading-title-24-bold {
  font-size: $heading-font-size-24;
  font-weight: bold;
}
.heading-title-24 {
  font-size: $heading-font-size-24;
  font-weight: normal;
}
.heading-title-20-bold {
  font-size: $heading-font-size-20;
  font-weight: bold;
}
.heading-title-20 {
  font-size: $heading-font-size-20;
  font-weight: normal;
}
.heading-title-18-bold {
  font-size: $heading-font-size-18;
  font-weight: bold;
}
.heading-title-18 {
  font-size: $heading-font-size-18;
  font-weight: normal;
}

.body-underline-bold {
  font-size: $default-font-size;
  font-weight: bold;
  text-decoration: underline;
}
.body-bold {
  font-size: $default-font-size;
  font-weight: bold;
}
.body-underline {
  font-size: $default-font-size;
  text-decoration: underline;
  font-weight: normal;
}
.body-regular {
  font-size: $default-font-size;
  font-weight: normal;
}
.body-italic {
  font-size: $default-font-size;
  font-style: italic;
  font-weight: normal;
}
.body-small-bold {
  font-size: $small-font-size;
  font-weight: bold;
  line-height: 1.36;
}
.body-small-underline {
  font-size: $small-font-size;
  line-height: 1.36;
  text-decoration: underline;
  font-weight: normal;
}
.body-small {
  font-size: $small-font-size;
  line-height: 1.36;
  font-weight: normal;
}
.body-small-italic {
  font-size: $small-font-size;
  font-style: italic;
  line-height: 1.36;
  font-weight: normal;
}

@mixin heading-title-40-bold {
  font-size: $heading-font-size-40;
  font-weight: bold;
}
@mixin heading-title-40 {
  font-size: $heading-font-size-40;
  font-weight: normal;
}
@mixin heading-title-36-bold {
  font-size: $heading-font-size-36;
  font-weight: bold;
}
@mixin heading-title-36 {
  font-size: $heading-font-size-36;
  font-weight: normal;
}
@mixin heading-title-32-bold {
  font-size: $heading-font-size-32;
  font-weight: bold;
  line-height: 1.28;
}
@mixin heading-title-32 {
  font-size: $heading-font-size-32;
  font-weight: normal;
}
@mixin heading-title-28-bold {
  font-size: $heading-font-size-28;
  font-weight: bold;
  line-height: 1.32;
}
@mixin heading-title-28 {
  font-size: $heading-font-size-28;
  font-weight: normal;
}
@mixin heading-title-24-bold {
  font-size: $heading-font-size-24;
  font-weight: bold;
}
@mixin heading-title-24 {
  font-size: $heading-font-size-24;
  font-weight: normal;
}
@mixin heading-title-20-bold {
  font-size: $heading-font-size-20;
  font-weight: bold;
}
@mixin heading-title-20 {
  font-size: $heading-font-size-20;
  font-weight: normal;
}
@mixin heading-title-18-bold {
  font-size: $heading-font-size-18;
  font-weight: bold;
}
@mixin heading-title-18 {
  font-size: $heading-font-size-18;
  font-weight: normal;
}
@mixin body-underline-bold {
  font-size: $default-font-size;
  font-weight: bold;
  text-decoration: underline;
}
@mixin body-bold {
  font-size: $default-font-size;
  font-weight: bold;
}
@mixin body-underline {
  font-size: $default-font-size;
  text-decoration: underline;
  font-weight: normal;
}
@mixin body-regular {
  font-size: $default-font-size;
  font-weight: normal;
}
@mixin body-italic {
  font-size: $default-font-size;
  font-style: italic;
  font-weight: normal;
}
@mixin body-small-bold {
  font-size: $small-font-size;
  font-weight: bold;
  line-height: 1.36;
}
@mixin body-small-underline {
  font-size: $small-font-size;
  line-height: 1.36;
  text-decoration: underline;
  font-weight: normal;
}
@mixin body-small {
  font-size: $small-font-size;
  line-height: 1.36;
  font-weight: normal;
}
@mixin body-small-italic {
  font-size: $small-font-size;
  font-style: italic;
  line-height: 1.36;
  font-weight: normal;
}
