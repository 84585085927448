:root {
  --text-color: #42495e;
}

:root,
:root.ptm {
  // Body
  --bg-color: #f5f7fb;
  --primary-color: #1f6beb;
  // Button primary
  --btn-primary-color: #20c151;
  --btn-primary-hover-color: #1cad48;
  --btn-primary-active-color: #a0cdfa;
  // Button secondary
  --btn-secondary-font-color: #42495e;
  --btn-secondary-hover-color: #f0f8fd;
  --btn-secondary-active-color: #d4eafc;
  // Navbar
  --navbar-bg: #ffffff;
  --navbar-font-color: #0379af;
  --navbar-address-color: #42495e;
}

:root.sdc {
  // Body
  --bg-color: #f5f7fb;
  --primary-color: #005d83;
  // Button primary
  --btn-primary-color: #005d83;
  --btn-primary-hover-color: #2d96b4;
  --btn-primary-active-color: #5ac3d6;
  // Button secondary
  --btn-secondary-font-color: #00264b;
  --btn-secondary-hover-color: #f2f6f8;
  --btn-secondary-active-color: #c2ecf0;
  // Navbar
  --navbar-font-color: #ffffff;
  --navbar-bg: #005d83;
  --navbar-address-color: #ffffff;
}

:root.chc {
  // Body
  --bg-color: #f5f7fb;
  --primary-color: #eea909;
  // Button primary
  --btn-primary-color: #eea909;
  --btn-primary-hover-color: #f7c36b;
  --btn-primary-active-color: #fee3b5;
  // Button secondary
  --btn-secondary-font-color: #aa7416;
  --btn-secondary-hover-color: #fdfaf4;
  --btn-secondary-active-color: #fff1da;
  // Navbar
  --navbar-bg: #ffffff;
}
