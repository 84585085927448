@import "../../assets//scss/_variables.scss";

.company-header {
  .company-name {
    font-size: 1.4rem;
  }
  .company-address {
    font-size: 0.9rem;
  }
  .company-website {
    font-size: 0.8rem;
  }
  .company-logo-container {
    .company-logo {
      // max-width: $companylogo-max-width;
      // max-height: $companylogo-max-height;
      border-style: none;
      vertical-align: middle;
    }
  }
}
