/* Override bootstrap style*/

.dc-dropdown {
  .dropdown-menu.show {
    min-width: 100%;
    max-height: 150px;
    overflow-y: auto;
    @include scroll-style-default;
  }

  a.dropdown-item.disabled {
    pointer-events: none;
    color: $grey-500;
    background-color: $grey-200;
  }
  .dropdown-item:hover {
    background-color: $grey-100;
  }
  .dropdown-item:active {
    background-color: $info-500;
  }

  .dropdown-toggle {
    &.error {
      border: 1px solid $danger-500;
      color: $danger-500;
    }
  }
}

.dc-dropdown.light button {
  height: 48px;
  width: 100%;
  border-radius: 4px;
  @include body-regular;
  text-align: left;
  margin: 0;
  border: 1px solid $grey-400;
  color: $grey-800;
  background-color: $white;

  // Small size
  &.small {
    height: 32px;
    line-height: 1.56 !important;
    @include body-small;
    margin-left: 6px;
  }
}

.dc-dropdown.dark button {
  height: 48px;
  width: 100%;
  min-width: 200px;
  border-radius: 4px;
  @include body-regular;
  text-align: left;
  border: 1px solid white;
  color: $white;
  background-color: transparent;

  // Small size
  &.small {
    line-height: 1.56 !important;
    height: 32px;
    @include body-small;
    margin-left: 6px;
  }
}

.dc-dropdown.light {
  .btn,
  button {
    &:hover,
    &:active,
    &:focus,
    &:visited,
    &:focus-visible,
    &:focus-within,
    &:target,
    &:active:hover {
      background-color: white !important;
    }
  }
}

.dc-dropdown.dark {
  .btn,
  button {
    &:hover,
    &:visited,
    &:focus-visible,
    &:focus-within,
    &:target {
      background-color: rgba(255, 255, 255, 0.2);
      color: $white !important;
      border: 1px solid $white !important;
    }

    &:active,
    &:focus,
    &:active:hover {
      background-color: rgba(255, 255, 255, 0.5);
      color: $white !important;
      border: 1px solid $white !important;
    }
  }
}

/* Prevent starting a new line */
.dropdown {
  display: inline;
}

.dc-dropdown .dropdown-menu,
.dc-dropdown .dropdown-menu a {
  user-select: none;
  @include body-regular;
  text-decoration: none;
  color: $grey-800;

  &:active,
  &:focus {
    color: white !important;
  }
}

.dc-dropdown .default.dropdown-toggle::after {
  float: right;
  margin-top: 10px;
  margin-right: 6px;
}

.dc-dropdown .small.dropdown-toggle::after {
  float: right;
  margin-top: 8px;
  margin-right: 0px;
}

.validation-error-message {
  display: flex;
  align-items: center;
  gap: 5px;
  color: $danger-500;
  margin-top: 5px;
  @include body-small-italic;
  svg,
  svg path {
    height: 13px;
    width: 13px;
  }
}
