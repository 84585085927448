.text-field-container {
  @include body-regular;
  background: $white;
  margin: 8px 0;

  // Custom icon color
  svg path {
    fill: $grey-800;
  }

  // Customize BS style
  .input-group-text {
    background-color: $white;
  }

  .icon-container {
    height: 48px;
    padding: 0 8px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    &.small {
      height: 32px;
    }
    &.left-icon {
      padding-left: 8px;
    }
    &.right-icon {
      padding-right: 8px;
    }
  }

  .text-field {
    width: 100%;
    padding: 0.5rem;
    line-height: 18px;
    border-radius: 4px;
    height: 48px;
    color: $grey-800;
    border: 1px solid $grey-400;

    &:focus-visible {
      outline: none;
    }

    &:focus {
      box-shadow: none;
    }

    &.left-icon {
      padding-left: 2.5rem;
    }
    &.right-icon {
      padding-right: 2.5rem;
    }

    &.small {
      height: 32px;
      padding-top: 0.5rem;
      padding-bottom: 0.375rem;
    }

    &.error {
      border: 1px solid $danger-500;
    }
  }

  .text-field::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $grey-600;
    opacity: 1; /* Firefox */
    font-weight: 500;
    font-family: $font-family-base;
    @include body-italic;
  }

  .text-field:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $grey-600;
    font-weight: 500;
    font-family: $font-family-base;
    @include body-italic;
  }

  .text-field::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $grey-600;
    font-weight: 500;
    font-family: $font-family-base;
    @include body-italic;
  }

  .text-field:disabled {
    background-color: $grey-100;
  }
}

.text-field-description {
  display: grid;
  grid-template-columns: 20px auto;
  @include body-small-italic;
  color: $grey-600;

  span {
    padding: 0 4px;
    margin-top: 2px;
  }
  div {
    display: flex;
    align-items: center;
  }
}

.text-field-error {
  padding-top: 4px;
  display: grid;
  grid-template-columns: 20px auto;
  @include body-small-italic;
  color: $danger-500;

  span {
    padding: 0 4px;
    margin-top: 2px;
  }
  div {
    display: flex;
    align-items: center;
  }
}
