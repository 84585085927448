//
// Base styles
//

.card {
  border: 1px solid $grey-400;
  box-shadow: 0px 0px 4px rgba(15, 23, 42, 0.14),
    0px 3px 4px rgba(15, 23, 42, 0.12), 0px 1px 5px rgba(15, 23, 42, 0.2);
  border-radius: 8px;
}
.card-body {
  padding: 24px;
  @media screen and (max-width: $tablet-device-max-width) {
    padding: 16px;
  }
}
