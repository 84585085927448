@import "../../assets//scss/_variables.scss";

.header-logo {
  border-style: none;
  vertical-align: middle;
  max-height: 75px;
  width: auto;
  height: auto;
  object-fit: contain;
}

@media print {
  .navbar {
    background-color: transparent !important;
    box-shadow: none !important;
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
  }
}
