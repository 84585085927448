html {
  scroll-behavior: smooth;
}

@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

body {
  font-weight: 400px;
  width: 100vw;
  overflow-x: hidden;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  background-color: var(--bg-color);
  color: var(--text-color);
  font-size: $default-font-size;
  @include scroll-style-default;

  > * {
    font-family: $font-family-base !important;
  }

  @media screen and (max-width: $tablet-device-max-width) {
    font-size: 0.8rem;
  }
}
