// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.
$font-family-base: OpenSans;

$heading-font-size-40: 40px;
$heading-font-size-36: 36px;
$heading-font-size-32: 32px;
$heading-font-size-28: 28px;
$heading-font-size-24: 24px;
$heading-font-size-20: 20px;
$heading-font-size-18: 18px;

$default-font-size: 14px;
$small-font-size: 12px;

// scss-docs-start badge-variables
$badge-font-size: 16px !default;

// Color system

// scss-docs-start gray-color-variables
$white: #fff !default;
$grey-100: #f5f7fb !default;
$grey-200: #eff3fa !default;
$grey-300: #e3eaf7 !default;
$grey-400: #d3ddee !default;
$grey-500: #b2bfd2 !default;
$grey-600: #8b98ad !default;
$grey-700: #656e85 !default;
$grey-800: #42495e !default;
$grey-900: #1c2136 !default;
$black: #000 !default;
// scss-docs-end gray-color-variables

// fusv-disable
// scss-docs-start gray-colors-map
$greys: (
  "100": $grey-100,
  "200": $grey-200,
  "300": $grey-300,
  "400": $grey-400,
  "500": $grey-500,
  "600": $grey-600,
  "700": $grey-700,
  "800": $grey-800,
  "900": $grey-900,
) !default;
// scss-docs-end gray-colors-map
// fusv-enable

// scss-docs-start color-variables
$blue: #0d6efd !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #198754 !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;
// scss-docs-end color-variables

// scss-docs-start colors-map
$colors: (
  "blue": $blue,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "white": $white,
  "gray": $grey-600,
  "gray-dark": $grey-800,
) !default;
// scss-docs-end colors-map

// scss-docs-start theme-color-variables
$primary: $blue !default;
$secondary: $grey-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $grey-100 !default;
$dark: $grey-900 !default;
// scss-docs-end theme-color-variables

// scss-docs-start theme-colors-map
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
) !default;
// scss-docs-end theme-colors-map

// fusv-disable
$success-100: #eafde8 !default;
$success-200: #b8f9b9 !default;
$success-300: #76ec82 !default;
$success-400: #51d96d !default;
$success-500: #20c151 !default;
$success-600: #17a550 !default;
$success-700: #108a4d !default;
$success-800: #0a6f47 !default;
$success-900: #065c42 !default;

$danger-100: #fde7e7 !default;
$danger-200: #f3caca !default;
$danger-300: #eaa4a4 !default;
$danger-400: #da6060 !default;
$danger-500: #c91b1b !default;
$danger-600: #b80000 !default;
$danger-700: #a60000 !default;
$danger-800: #860000 !default;
$danger-900: #5b0000 !default;

$info-100: #f0f8fd !default;
$info-200: #d4eafc !default;
$info-300: #a0cdfa !default;
$info-400: #5c9cef !default;
$info-500: #1f6beb !default;
$info-600: #1b55d6 !default;
$info-700: #153eb9 !default;
$info-800: #112a9c !default;
$info-900: #0c1a7e !default;

$warning-100: #fbf6dc !default;
$warning-200: #faedb7 !default;
$warning-300: #f6dd8a !default;
$warning-400: #f0c346 !default;
$warning-500: #eea909 !default;
$warning-600: #d18500 !default;
$warning-700: #b56800 !default;
$warning-800: #984e00 !default;
$warning-900: #753500 !default;

$branding-sdc-100: #f2f6f8;
$branding-sdc-200: #c2ecf0;
$branding-sdc-300: #5ac3d6;
$branding-sdc-400: #2d96b4;
$branding-sdc-500: #005d83;
$branding-sdc-600: #004870;
$branding-sdc-700: #00365e;
$branding-sdc-800: #00264b;
$branding-sdc-900: #001b3e;

$product-mustard-100: #fdfaf4;
$product-mustard-200: #fff1da;
$product-mustard-300: #fee3b5;
$product-mustard-400: #f7c36b;
$product-mustard-500: #eea320;
$product-mustard-600: #d7931d;
$product-mustard-700: #c1831a;
$product-mustard-800: #aa7416;
$product-mustard-900: #936413;

$product-purple-100: #faf8fd;
$product-purple-200: #dfd4ed;
$product-purple-300: #c5b3de;
$product-purple-400: #af99ce;
$product-purple-500: #9e85bf;
$product-purple-600: #917baf;
$product-purple-700: #8470a0;
$product-purple-800: #776590;
$product-purple-900: #67577c;

$product-salmon-100: #fff5f4;
$product-salmon-200: #fad7d2;
$product-salmon-300: #f3b9b0;
$product-salmon-400: #ee9d91;
$product-salmon-500: #e88273;
$product-salmon-600: #da7a6c;
$product-salmon-700: #c97063;
$product-salmon-800: #b7675b;
$product-salmon-900: #a65e53;

$product-orange-100: #fff9f6;
$product-orange-200: #f6d5c5;
$product-orange-300: #f2b79b;
$product-orange-400: #eda17d;
$product-orange-500: #e86e34;
$product-orange-600: #d96731;
$product-orange-700: #cb602d;
$product-orange-800: #bc592a;
$product-orange-900: #ad5125;

$successes: (
  "success-100": $success-100,
  "success-200": $success-200,
  "success-300": $success-300,
  "success-400": $success-400,
  "success-500": $success-500,
  "success-600": $success-600,
  "success-700": $success-700,
  "success-800": $success-800,
  "success-900": $success-900,
) !default;

$dangers: (
  "danger-100": $danger-100,
  "danger-200": $danger-200,
  "danger-300": $danger-300,
  "danger-400": $danger-400,
  "danger-500": $danger-500,
  "danger-600": $danger-600,
  "danger-700": $danger-700,
  "danger-800": $danger-800,
  "danger-900": $danger-900,
) !default;

$infos: (
  "info-100": $info-200,
  "info-200": $info-100,
  "info-300": $info-300,
  "info-400": $info-400,
  "info-500": $info-500,
  "info-600": $info-600,
  "info-700": $info-700,
  "info-800": $info-800,
  "info-900": $info-900,
) !default;

$warnings: (
  "warning-100": $warning-100,
  "warning-200": $warning-200,
  "warning-300": $warning-300,
  "warning-400": $warning-400,
  "warning-500": $warning-500,
  "warning-600": $warning-600,
  "warning-700": $warning-700,
  "warning-800": $warning-800,
  "warning-900": $warning-900,
) !default;
// fusv-enable

// Based on hi-res tablet like iPad Air
$tablet-device-max-width: 896px;

$screen-xs-max-width: 576px;
$screen-sm-max-width: 768px;
$screen-md-max-width: 992px;
$screen-lg-max-width: 1200px;
$screen-xl-max-width: 1400px;

// Common
$link-color: #0d6efd;
$link-hover-color: #0b55d6;
