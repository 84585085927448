// Contents
//
// Navbar
// Navbar brand
// Navbar nav
// Navbar text
// Responsive navbar
// Navbar position
// Navbar themes

// Navbar
//
// Provide a static navbar from which we expand to create full-width, fixed, and
// other navbar variations.

.navbar {
  background-color: var(--navbar-bg);
  min-height: 64px;
  box-shadow: 0px 0px 1px rgba(15, 23, 42, 0.06),
    0px 4px 6px -1px rgba(15, 23, 42, 0.1),
    0px 2px 4px -1px rgba(15, 23, 42, 0.06);

  &.large {
    min-height: 113px;
    @media screen and (max-width: $tablet-device-max-width) {
      min-height: 64px;
    }
  }

  // Navbar brand
  //
  // Used for brand, project, or site names.

  .navbar-brand {
    color: var(--navbar-font-color) !important;
    @include heading-title-18-bold;
  }

  .navbar-nav {
    max-width: 530px;
    color: var(--navbar-address-color);
    @include body-small;
  }

  .nav-content {
    @media screen and (max-width: $tablet-device-max-width) {
      display: none;
    }
  }
}
